<template>
  <div>
    <r-header :title="'缴纳电费'" :isLeftArrow="true" :isR="true" @back="backPage"></r-header>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" style="margin-top:1rem;">
      <van-list
        v-model="loading"
        :finished="finished"
        offset="300"
        @load="onLoad"
      >
        <template v-if="list.length > 0">
          <van-cell-group
            v-for="item in list"
            :key="item.id">
            <van-card
              :thumb="'/apis'+ item.dianpuimg"
              class="card-style"
            >
              <template #title>
                <p class="list-title">{{ item.dianpuname }}</p>
              </template>
              <template #desc>
                <p class="text">开始码：{{ item.startnum }}</p>
                <p class="text">结束码：{{ item.endnum }}</p>
                <!-- <p class="text">单价：{{ item.diandanjia }}</p>
                <p class="text">公摊：{{ item.gongtan }}</p> -->
                <!-- <p class="text">开始时间：{{ item.starttime }}</p>
                <p class="text">结束时间：{{ item.endtime }}</p> -->
              </template>
              <template #footer>
                <div>
                  <!-- <van-button v-if="item.feistatus === '1'" style="margin-left:20px;" type="primary" size="small" @click="goPay(item.id)">去缴费</van-button> -->
                  <p class="text-m">￥{{ item.amt }}</p>
                </div>
                <p class="text mt-10">状态：{{ item.feistatus === '1' ? '待交费' : '缴费完成' }}</p>
                <p class="text" v-if="item.feistatus === '1'">小计： <span class="money">￥{{ item.amt }}</span></p>
              </template>
            </van-card>
          </van-cell-group>
        </template>
        <van-empty v-if="!loading && list.length < 1" image="search" description="暂无数据" />
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import RHeader from '@/components/RHeader.vue'
export default {
  name: 'ElectricList',
  components: {
    RHeader
  },
  data () {
    return {
      list: [],
      loading: true,
      finished: false,
      refreshing: false,
      page: 1,
      page_size: 10,
      total: 0,
      pages: 0
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    initData () {
      this.$axios.post('/gw/shuidianinfos', {
        shuidianfeitype: 2,
        currentPage: this.page,
        pageSize: this.page_size
      }).then(res => {
        if (res.data.code === 200) {
          if (res.data.data.length === 0) {
            this.list = []
            this.finished = true
          }
          this.total = res.data.total
          const dataList = res.data.data
          if (this.page === 1) {
            this.list = dataList
          } else {
            this.list = this.list.concat(dataList)
          }
          this.loading = false
          if (this.list.length >= res.data.total) {
            this.finished = true
            this.loading = false
          }
          this.page++
        } else if (res.data.code === 100) {
          this.finished = true
          this.loading = false
          this.$router.push({
            name: 'login'
          })
        }
      }).catch(() => {
        this.finished = true
        this.loading = false
      })
    },
    onLoad () {
      if (!this.timer) {
        this.timer = setTimeout(() => {
          this.initData()
          this.timer = null
        }, 500)
      }
    },
    onRefresh () {
      this.loading = true
      this.page = 1
      this.list = []
      this.refreshing = true
      this.onLoad()
      this.finished = false
      this.refreshing = false
    },
    goPay (id) {
      this.$router.push({
        name: 'ElectricPay',
        query: {
          id
        }
      })
    },
    backPage () {
      this.$router.push('/mine')
    }
  }
}
</script>

<style lang="less" scoped>
.card-style{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list-title {
  font-size: 20px;
  margin-bottom: 10px;
}
</style>
