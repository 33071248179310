<template>
  <header class="r-nav-header">
    <div class="left" :class="{'flex-b': isLeftArrow || leftText || isL}" @click="isLeftArrow && routerback()">
      <div v-if="isLeftArrow" class="arrow-left">
        <van-icon name="arrow-left" size="24" />
      </div>
      <span class="left-text" v-if="leftText">{{leftText}}</span>
    </div>
    <div class="content-head flex-g" :class="{'title': title}">
      <span class="t-conent" v-if="title">{{title}}</span>
      <slot name="content"></slot>
    </div>
    <div class="right" :class="{'flex-b': isR}">
      <slot name="right"></slot>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    leftText: {
      type: String,
      default: ''
    },
    isLeftArrow: {
      type: Boolean,
      default: false
    },
    isR: {
      type: Boolean,
      default: false
    },
    isL: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      value: ''
    }
  },
  methods: {
    routerback () {
      this.$emit('back')
    },
    onClickRight () {}
  }
}
</script>

<style lang="less">
.r-nav-header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 1rem;
  background: #fff;
  display: flex;
  align-items: center;
  .left{
    display: flex;
    align-items: center;
    padding-left: 10px;
    .arrow-left{
      margin-top: -12px;
      // margin-left: 10px;
    }
    .left-text{
      font-size: 18px;
      margin-left: 5px;
    }
  }
  .right{
    padding-right: 10px;
    >div{
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .content-head{
    align-items: center;
    justify-content: center;
    .t-conent{
      font-size: 22px!important;
      text-align: center;
    }
  }
  .title{
    display: flex;
    width: 100%;
  }
  .flex-b{
    flex-basis: 100px;
  }
  .flex-g{
    flex-grow: 1;
  }
}
</style>
